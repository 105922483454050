import React from 'react';
import {
  Box,
  IconButton,
  Select,
  MenuItem
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { deleteOrderV2, updateOrderV2 } from '../../api/ordersV2.js'


export default function OrdersV2({
  rows,
  setRows,
  setSelectedOrder,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading
}) {

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrderV2(id);
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      setSnackbarOpen(true);
      setSnackbarMessage('Order deleted successfully.');
      setSnackbarAlert('success');
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error deleting order.');
      setSnackbarAlert('error');
    }
  };

  const handleProcessRowUpdate = async (updatedRow, oldRow) => {
    await updateOrderV2(updatedRow.id, updatedRow);
    const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setRows(updatedRows);
    return updatedRow;
  };

  const columns = [
    { field: 'order_name', headerName: 'Order Name', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const handleChange = (event) => {
          const updatedStatus = event.target.value;
          const updatedRow = { ...params.row, status: updatedStatus };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={params.value}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              <MenuItem value="PO Sent">PO Sent</MenuItem>
              <MenuItem value="In Transit">In Transit</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
            </Select>
          </>
        )
      }
    },
    {
      field: 'integration_id', headerName: 'Partner', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={value.id}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map((integration) => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      },
    },
    {
      field: 'integration_id_to', headerName: 'Partner To', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id_to: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={value?.id}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map((integration) => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      },
    },
    {
      field: 'total_cost', headerName: 'Total Cost', flex: 1,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
    },
    {
      field: 'header_detail', headerName: 'Items', flex: 0.5,
      renderCell: (params) => {
        const value = params.value;
        if (value) {
          return value.length;
        }
      },
    },
    {
      field: 'order_date', headerName: 'Order Date', width: 140,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'last_updated', headerName: 'Last Updated', width: 140,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            key={`view-icon-${params.row.id}`}
            onClick={() => setSelectedOrder(params.row)}
          >
            <ViewInArIcon sx={{ color: 'black' }} />
          </IconButton>
          <IconButton
            key={`delete-icon-${params.row.id}`}
            onClick={() => handleDeleteOrder(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      bgcolor: 'white',
      border: '1px solid #ccc',
      borderRadius: '15px',
      height: '83vh',
      maxHeight: '83vh',
    }}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          density="compact"
          loading={loading}
          disableRowSelectionOnClick
          hideFooter
        />
      </Box>
    </Box>
  );
}
