import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import AddIcon from '@mui/icons-material/Add';
import {
  fetchDocumentContent,
  createDocument,
  updateDocument,
  deleteDocument,
  analyzeOrder
} from '../../api/documents.js';
import { createOrderV2, togglePinDocumentV2 } from '../../api/ordersV2.js'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OrderDetailViewer from '../../components/OrderDetailViewer';
import PDFViewer from '../../components/PDFViewer.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const BackButton = ({ setBlobUrl, setParsedDocument, buttonText = "Back to Table", ...props }) => {
  return (
    <Button
      color="inherit"
      variant="outlined"
      sx={{ width: '35%', height: '2.5rem', mt: '1rem' }}
      startIcon={<ArrowBackIcon />}
      onClick={() => {
        if (setBlobUrl) setBlobUrl(null);
      }}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

const docTypes = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Sales Order', label: 'Sales Order' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Receiving Instructions', label: 'Receiving Instructions' },
  { value: 'Receiving Order', label: 'Receiving Order' },
  { value: 'Certifications', label: 'Certifications' },
  { value: 'Bill of Lading', label: 'Bill of Lading' },
  { value: 'Upload', label: 'Upload' },
  { value: 'Other', label: 'Other' },
];

export default function DocumentsV2({
  rows,
  setRows,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading
}) {
  const [blobUrl, setBlobUrl] = useState(null);
  const [blobType, setBlobType] = useState(null);
  const fileInputRef = useRef(null);
  const [parsedPdf, setParsedPdf] = useState("");
  const [orderDetailDialog, setOrderDetailDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [isChanged, setIsChanged] = useState(true);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'application/zip')
    ) {
      uploadDocument(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDF, PNG, and JPEG are allowed.');
    }
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const response = await createDocument(integrations.find((integration) => integration.integration_type.category === "HQ").id, file.name, b64encodedString);
        setRows([response, ...rows]);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      }
    };
  };

  const handlePasteClipboard = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.read) {
        const clipboardItems = await navigator.clipboard.read();

        for (const item of clipboardItems) {
          if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
            const blob = await item.getType(item.types[0]);
            const timestamp = (new Date()).toISOString().replace(/[:.]/g, '-');
            const file = new File([blob], `pasted-${item.types[0].split('/')[1]}-${timestamp}.${item.types[0].split('/')[1]}`, { type: blob.type });
            uploadDocument(file);
          }
        }
      } else {
        console.log("Clipboard access not supported or allowed.");
      }
    } catch (error) {
      console.error("Failed to read clipboard contents:", error);
    }
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    try {
      const data = await fetchDocumentContent(row.msg_id, row.document_name);
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.document_name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.document_name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.document_name.endsWith('.jpg') || row.document_name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.document_name.endsWith('.zip')) {
        mimeType = 'application/zip';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.document_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeleteDocument = async (id) => {
    try {
      await deleteDocument(id);
      setRows(rows.filter((r) => r.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  }

  const handleDocumentChange = async (event, row, changedVal) => {
    try {
      const updatedValue = event.target.value;
      let updatedRow = { ...row };
      if (changedVal === 'category') {
        updatedRow = { ...updatedRow, category: updatedValue };
      }
      if (changedVal === 'order_name') {
        updatedRow = { ...updatedRow, order_name: updatedValue };
      }
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_id: updatedValue };
      }
      if (changedVal === 'is_published') {
        updatedRow = { ...updatedRow, is_published: updatedValue };
      }
      const data = await updateDocument(updatedRow);
      updatedRow.id = data.id;
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_name: integrations.find((integration) => integration.id === updatedValue)?.name };
      }
      setRows(rows.map((r) => (r.id === row.id ? updatedRow : r)));
      setSnackbarOpen(true);
      setSnackbarMessage("Updated.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleAnalyzeDocument = async (document_id) => {
    try {
      const data = await analyzeOrder(document_id);
      if (data.body.order_name) {
        const line_items = data.body.line_items.map((item, index) => ({ ...item, id: index + 1 }));
        data.body.header_detail = line_items;
        setParsedPdf(data.body)
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handleRowUpdate = async (newRow, oldRow) => {
    const oldRowExtension = oldRow.document_name.split('.').pop();
    if (!newRow.document_name.endsWith(`.${oldRowExtension}`)) {
      newRow.document_name = `${newRow.document_name}.${oldRowExtension}`;
    }

    await updateDocument(newRow);
    return newRow;
  };

  const handleOrderClose = async () => {
    console.log(isChanged)
    try {
      const pdfWithIntegrationId = {
        ...parsedPdf,
        integration_id: selectedDocument.integration_id,
        document_id: selectedDocument.id
      }
      const updatedParsedPdf = { ...parsedPdf, line_items: parsedPdf.header_detail };
      const data = await createOrderV2({ ...pdfWithIntegrationId, line_items: updatedParsedPdf.line_items });
      console.log(data)
      setRows(rows.map((r) => (r.id === selectedDocument.id ? { ...r, order_header_id: data.order_id } : r)));
      setSnackbarOpen(true);
      setSnackbarMessage("Saved.");
      setSnackbarAlert("success");
      setBlobType("");
      setBlobUrl("");
      setOrderDetailDialog(false);
      setParsedPdf("");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleCloseNoSave = async () => {
    setOrderDetailDialog(false);
    setBlobType("");
    setBlobUrl("");
    setOrderDetailDialog(false);
    setParsedPdf("");
  };


  const columns = [
    { field: 'source', headerName: 'Data Source', flex: 0.5 },
    {
      field: 'integration_name',
      headerName: 'Partner',
      flex: 0.5,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        if (params.row.integration_id === integrations.find(integration => integration.integration_type.category === "HQ")?.id) {
          return (
            <Select
              value={params.row.integration_id}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "integration_id")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map(integration => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.integration_name;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.75,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        if (params.row.source !== 'Email') {
          return (
            <Select
              value={params.row.category}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "category")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {docTypes.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.category;
      },
    },
    {
      field: 'document_name',
      headerName: 'Document',
      flex: 1.5,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
    },
    {
      field: 'msg_timestamp', headerName: 'Created At', width: 140,
      renderCell: (params) => {
        const value = params.value || params.row.create_time;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => {
        const icons = [];
        const documentName = params.row.document_name;
        const isViewable = /\.(pdf|png|jpeg)$/i.test(documentName);
        icons.push(
          <Tooltip key={`${params.row.id}-pin`} title={params.row.is_pinned ? "Unpin Document" : "Pin Document"}>
            <IconButton onClick={async () => {
              try {
                await togglePinDocumentV2(params.row.id);
                setRows(rows.map((r) => (r.id === params.row.id ? { ...r, is_pinned: !r.is_pinned } : r)));
              } catch (error) {
                console.error('Error:', error);
              }
            }}>
              {params.row.is_pinned ? <PushPinIcon sx={{ color: 'black' }} /> : <PushPinOutlinedIcon sx={{ color: 'black' }} />}
            </IconButton>
          </Tooltip>
        )
        icons.push(
          <Tooltip title={isViewable ? "View Document" : "Download Document"} key={`${documentName}-${isViewable ? 'view' : 'download'}`}>
            <IconButton onClick={() => handleFetchDocument(params.row)}>
              {isViewable ? <SearchOutlinedIcon sx={{ color: 'black' }} /> : <DownloadIcon sx={{ color: 'black' }} />}
            </IconButton>
          </Tooltip>
        );
        if (params.row.order_header_id) {
          icons.push(
            <Tooltip key={`${params.row.id}-linked`} title="Document is linked">
              <IconButton
                key={`link-icon-${params.row.id}`}
              >
                <CheckCircleIcon color='success' />
              </IconButton>
            </Tooltip>
          )
        }
        if (params.row.source === 'Email') {
          icons.push(
            <IconButton
              key={`email-icon-${params.row.id}`}
              component="a"
              href={`https://mail.google.com/mail/u/0/#inbox/${params.row.msg_id}`}
              target="_blank"
            >
              <EmailOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        } if (['Sales Order', 'Purchase Order', 'Receiving Order'].includes(params.row.category) && !params.row.order_header_id && !params.row.document_name.endsWith('.zip')) {
          icons.push(
            <IconButton
              key={`genai-icon-${params.row.id}`}
              onClick={() => {
                setSelectedDocument(params.row);
                handleAnalyzeDocument(params.row.id);
                setOrderDetailDialog(true);
                handleFetchDocument(params.row);
              }}
            >
              <AutoFixHighIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        } if (params.row.source === 'Upload') {
          icons.push(
            <IconButton
              key={`delete-icon-${params.row.id}`}
              onClick={() => handleDeleteDocument(params.row.id)}
            >
              <DeleteOutlineOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        }
        return <>{icons}</>;
      },
      headerClassName: 'super-app-theme--header-new'
    }
  ];


  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <>
          <Box sx={{ display: 'flex', px: '0.25rem', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleUploadClick}
              sx={{
                borderRadius: '10px',
              }}
            >
              Add Document
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              sx={{ borderRadius: '10px' }}
              color='inherit'
              startIcon={<ContentPasteIcon />}
              onClick={handlePasteClipboard}
            >
              Click to paste from clipboard
            </Button>
          </Box>
        </>
      </GridToolbarContainer >
    );
  };


  const ViewerToolbar = () => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <GridToolbarContainer>
            <BackButton
              setBlobUrl={setBlobUrl}
              sx={{}}
            />
          </GridToolbarContainer>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          bgcolor: 'white',
          border: '1px solid #ccc',
          borderRadius: '15px',
          height: '83vh',
          maxHeight: '83vh',
        }}
      >
        {!blobUrl ? (
          <Box sx={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density="compact"
              loading={loading}
              disableRowSelectionOnClick
              processRowUpdate={handleRowUpdate}
              hideFooter
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Box>
        ) : !selectedDocument ? (
          <>
            <Box sx={{ width: '50%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density="compact"
                columnVisibilityModel={{
                  source: false,
                  edited_by: false,
                  last_updated: false,
                }}
                hideFooter
                slots={{
                  toolbar: ViewerToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            {blobType === 'application/pdf' ? (
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                height="100%"
                maxHeight="70vh"
                overflow="auto"
              >
                <PDFViewer pdfBlob={blobUrl} />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                height="100%"
                maxHeight="70vh"
                overflow="auto"
              >
                <img src={blobUrl} alt="docImg" />
              </Box>
            )}
          </>
        ) : (
          <>
            <Box sx={{ position: 'relative' }}>
              <Backdrop
                sx={{
                  color: 'white',
                  zIndex: 1300,
                }}
                open={selectedDocument}
              >
                <CircularProgress size={20} color="inherit" sx={{ mr: '1rem' }} />
                <Typography variant="body1">Analyzing...</Typography>
              </Backdrop>
            </Box>
            <Box sx={{ width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density="compact"
                loading={loading}
                disableRowSelectionOnClick
                processRowUpdate={handleRowUpdate}
                hideFooter
                slots={{
                  toolbar: GridToolbar,
                }}
              />
            </Box>
          </>
        )}
      </Box>
      <OrderDetailViewer
        dialogOpen={orderDetailDialog}
        handleClose={handleOrderClose}
        handleCloseNoSave={handleCloseNoSave}
        setIsChanged={setIsChanged}
        order={parsedPdf}
        setOrder={setParsedPdf}
        blobTypeInput={blobType}
        blobUrlInput={blobUrl}
      />
    </>
  );
}
