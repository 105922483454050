import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { updateIntegration } from '../api/integrations';
import CustomTextField from './CustomTextField';

const UpdatePartnerDialog = ({
  dialogOpen,
  handleClose,
  integration,
  setIntegration,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) => {

  const handleSubmit = async () => {
    try {
      const updatedIntegration = await updateIntegration(integration.id, integration);
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((int) =>
          int.id === integration.id ? updatedIntegration : int
        )
      );
      handleClose();
    } catch (error) {
      console.error('Error updating integration:', error);
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage(error.response.data.detail);
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{
        style: {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <DialogTitle>Edit Partner</DialogTitle>
      <DialogContent>
        <CustomTextField
          label={"Partner Name"}
          value={integration.name}
          sx={{ my: '0.5rem' }}
          onChange={(e) => setIntegration({...integration, name: e.target.value})}
        />
        <CustomTextField
          label="Contact Email"
          value={integration.contact_email}
          onChange={(e) => setIntegration({...integration, contact_email: e.target.value})}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePartnerDialog;
