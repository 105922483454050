import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Divider,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { fetchDocuments, fetchDocumentContent, analyzeOrder } from '../api/documents';
import { updateOrderDocumentsV2, unlinkDocumentV2 } from '../api/ordersV2';
import PDFViewer from '../components/PDFViewer.js';


const CustomTextField = ({ label, value, onChange, multiline, disabled = false }) => (
  <TextField
    label={label}
    value={value || ''}
    fullWidth
    margin="dense"
    disabled={disabled}
    autoComplete='off'
    multiline={multiline}
    size="small"
    onChange={onChange}
    InputProps={{
      style: {
        borderRadius: '10px',
      },
    }}
  />
);

const OrderDetailViewer = ({
  dialogOpen,
  handleClose,
  setIsChanged,
  order,
  setOrder,
  handleCloseNoSave = null,
  blobTypeInput = null,
  blobUrlInput = null
}) => {
  const [documentOptions, setDocumentOptions] = useState([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [blobType, setBlobType] = useState("");
  const [tempDoc, setTempDoc] = useState(null);

  useEffect(() => {
    const loadDocuments = async () => {
      if (dialogOpen) {
        const documents = await fetchDocuments();
        setDocumentOptions(documents.filter((doc) => doc.order_header_id === null));
        if (blobUrlInput && blobTypeInput) {
          setBlobUrl(blobUrlInput);
          setBlobType(blobTypeInput);
        }
      }
    };
    loadDocuments();
  }, [dialogOpen, blobUrlInput, blobTypeInput]);

  if (!order) return null;


  const columns = [
    { field: 'item_name', headerName: 'Item', flex: 2, headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'order_qty', headerName: 'Order Qty', flex: 1, type: 'number', headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'invoice_qty', headerName: 'Invoice Qty', flex: 1, type: 'number', headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'item_unit_of_measure', headerName: 'Unit of Measure', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
    {
      field: 'item_price', headerName: 'Unit Price', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true,
      renderCell: (params) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
    },
    { field: 'lot_number', headerName: 'Lot Number', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'expiration_date_raw', headerName: 'Expiration Date', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
  ];


  const handleTextFieldChange = (e, field) => {
    setIsChanged(true);
    const { value } = e.target;
    setOrder((prevOrder) => ({
      ...prevOrder,
      [field]: value,
    }));
  };

  const rows = (order?.header_detail || []).map((detail, index) => ({
    id: detail.id,
    item_name: detail.item_name,
    order_qty: detail.order_qty,
    invoice_qty: detail.invoice_qty,
    item_price: detail.item_price,
    item_unit_of_measure: detail.item_unit_of_measure,
    lot_number: detail.lot_number,
    expiration_date_raw: detail.expiration_date_raw,
  }));

  const handleRowUpdate = (newRow, oldRow) => {
    setIsChanged(true);
    const updatedRow = {
      id: newRow.id,
      item_name: newRow.item_name,
      order_qty: newRow.order_qty !== "" ? parseFloat(newRow.order_qty) : null,
      invoice_qty: newRow.invoice_qty !== "" ? parseFloat(newRow.invoice_qty) : null,
      item_price: newRow.item_price !== "" ? parseFloat(newRow.item_price) : null,
      item_unit_of_measure: newRow.item_unit_of_measure || null,
      lot_number: newRow.lot_number || null,
      expiration_date_raw: newRow.expiration_date_raw || null,
    };
    const updatedRows = rows.map((row) =>
      row.id === oldRow.id ? { ...row, ...updatedRow } : row
    );
    console.log(updatedRows);
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_detail: updatedRows,
    }));
    return newRow;
  };

  const handleMapDocument = async (selectedDoc) => {
    await updateOrderDocumentsV2(order.id, selectedDoc.id)
    const newDocumentEntry = {
      id: selectedDoc.id,
      order_header_id: order.id,
      integration_id: selectedDoc.integration_id,
      category: selectedDoc.category,
      msg_id: selectedDoc.msg_id,
      document_name: selectedDoc.document_name,
    };

    setOrder((prevOrder) => ({
      ...prevOrder,
      header_document: [...(prevOrder.header_document || []), newDocumentEntry],
    }));

    setTempDoc(null);
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl("");
    setBlobType("");
    try {
      const data = await fetchDocumentContent(row.msg_id, row.document_name);
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.document_name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.document_name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.document_name.endsWith('.jpg') || row.document_name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.document_name.endsWith('.zip')) {
        mimeType = 'application/zip';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.document_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUnlinkDocument = async (row) => {
    setIsChanged(true);
    await unlinkDocumentV2(row.id);
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_document: (prevOrder.header_document || []).filter((item) => item.id !== row.id),
    }));
  };


  const handleAnalyzeDocument = async (document_id) => {
    setIsChanged(true);
    try {
      const data = await analyzeOrder(document_id);
      setOrder((prevOrder) => {
        const updatedOrder = { ...prevOrder };
        Object.keys(data.body).forEach((key) => {
          if (key === 'bill_of_lading') {
            if (updatedOrder.bol === undefined || updatedOrder.bol === null || updatedOrder.bol === "") {
              updatedOrder.bol = data.body.bill_of_lading;
            }
          }
          else if (updatedOrder[key] === undefined || updatedOrder[key] === null || updatedOrder[key] === "") {
            updatedOrder[key] = data.body[key];
          }
        });
        return updatedOrder;
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth={!blobType ? "lg" : "xl"}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'white',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h6" component="span" display={'flex'} alignItems="center" sx={{ mb: 2 }}>
          <ViewInArIcon sx={{ mr: 1 }} />Order {order.order_name}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={!blobType ? 12 : 6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      size='small'
                      margin="dense"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                        },
                      }}
                    >
                      <InputLabel id="status-select-label">Status</InputLabel>
                      <Select
                        labelId="status-select-label"
                        value={order.status}
                        label="Status"
                        onChange={(e) => handleTextFieldChange(e, 'status')}
                      >
                        <MenuItem value="PO Sent">PO Sent</MenuItem>
                        <MenuItem value="In Transit">In Transit</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      label="Order Date"
                      disabled
                      value={order.order_date}
                      onChange={(e) => handleTextFieldChange(e, 'order_date')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="span" display={'flex'} alignItems={'center'}>
                  <LocalShippingIcon sx={{ mx: 1 }} />
                  <b>Shipping Info</b>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Address To"
                      value={order.ship_to_address}
                      onChange={(e) => handleTextFieldChange(e, 'ship_to_address')}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Tracking Number"
                      value={order.tracking_number}
                      onChange={(e) => handleTextFieldChange(e, 'tracking_number')}
                    />
                    <CustomTextField
                      label="BOL"
                      value={order.bol}
                      onChange={(e) => handleTextFieldChange(e, 'bol')}
                    />
                    <CustomTextField
                      label="Carrier"
                      value={order.carrier}
                      onChange={(e) => handleTextFieldChange(e, 'carrier')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Pick-Up Date"
                      value={order.pick_up_date}
                      onChange={(e) => handleTextFieldChange(e, 'pick_up_date')}
                    />
                    <CustomTextField
                      label="Delivery Date"
                      value={order.delivery_date}
                      onChange={(e) => handleTextFieldChange(e, 'delivery_date')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="span" display={'flex'} alignItems={'center'}>
                  <AttachMoneyIcon sx={{ mx: 1 }} />
                  <b>Invoice Info</b>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Total Freight ($)"
                      value={order.total_freight}
                      onChange={(e) => handleTextFieldChange(e, 'total_freight')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Total Tax ($)"
                      value={order.total_tax}
                      onChange={(e) => handleTextFieldChange(e, 'total_tax')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Total Cost ($)"
                      value={order.total_cost}
                      onChange={(e) => handleTextFieldChange(e, 'total_cost')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="span" display={'flex'} alignItems={'center'} flexWrap={'wrap'} gap={1}>
                  <FormControl
                    size="small"
                    sx={{
                      minWidth: 250,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <InputLabel id="doc-select-label">Link Document</InputLabel>
                    <Select
                      labelId="doc-select-label"
                      id="doc-select"
                      label="Link Document"
                      value={tempDoc || ""}
                      onChange={(e) => handleMapDocument(e.target.value)}
                    >
                      {documentOptions?.map((doc) => (
                        <MenuItem key={doc.id} value={doc}>
                          {doc.document_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {(order?.header_document || []).map((doc) => (
                    <React.Fragment key={doc.id}>
                      <Typography variant="body2" component="span" display={'inline'} sx={{ mr: 0.2 }}>
                        {doc.document_name}
                        {
                          (['Bill of Lading', 'Invoice'].includes(doc.category) && !doc.document_name.endsWith('.zip')) ? (
                            <IconButton sx={{ m: 0 }} onClick={() => {
                              handleFetchDocument(doc)
                              handleAnalyzeDocument(doc.id)
                            }}>
                              <AutoFixHighIcon color='info' />
                            </IconButton>
                          ) : (
                            <IconButton sx={{ m: 0 }} onClick={() => handleFetchDocument(doc)}>
                              <SearchOutlinedIcon color='info' />
                            </IconButton>
                          )
                        }
                        <IconButton sx={{ m: 0 }} onClick={() => handleUnlinkDocument(doc)}>
                          <CloseIcon color='error' />
                        </IconButton>
                      </Typography>
                    </React.Fragment>
                  ))}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <div style={{ height: 350, maxHeight: 350, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                disableSelectionOnClick
                density="compact"
                disableRowSelectionOnClick
                hideFooter
                processRowUpdate={handleRowUpdate}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </div>
          </Grid>
          {blobType && (
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" width="100%" height="75vh" maxHeight="75vh" overflow={'hide'} mt={1}>
                <Box display="flex" flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<ArrowBackIcon />}
                    sx={{
                      height: '2.5rem',
                      maxHeight: '2.5rem',
                      width: '25%',
                      maxWidth: '25%',
                    }}
                    onClick={() => {
                      setBlobUrl("");
                      setBlobType("");
                    }}
                  >
                    Close
                  </Button>
                </Box>
                {blobType === 'application/pdf' ? (
                  <Box display="flex" flexDirection="column" width="100%" height="100%">

                    <PDFViewer pdfBlob={blobUrl} />
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" width="100%" height="100%">
                    <img src={blobUrl} alt="docImg" />
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {handleCloseNoSave && (
          <Button onClick={handleCloseNoSave} color="primary">
            Close
          </Button>
        )}
        <Button onClick={handleClose} color="primary">
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDetailViewer;
