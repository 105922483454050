import React, { useCallback } from 'react';
import { Popover, Box, Typography, IconButton } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { fetchDocumentContent } from '../api/documents.js';

const DocumentsPopover = ({ anchorEl, setAnchorEl, selectedDocuments }) => {
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleDownload = async (msg_id, document_name) => {
    const data = await fetchDocumentContent(msg_id, document_name);
    const binaryData = atob(data);
    const byteNumbers = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers]);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', document_name);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', p: '1rem', width: '100%', justifyContent: 'flex-start' }}>
        <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
          <b>Pinned Documents</b>
        </Typography>
        {selectedDocuments.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '0.5rem' }}>
            <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
              {item.document_name}
            </Typography>
            <IconButton onClick={() => handleDownload(item.msg_id, item.document_name)}>
              <DownloadIcon fontSize='small' />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default DocumentsPopover;
