import React from 'react';
import {
  Box,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export default function OrderDetailsV2({
  rows,
  loading
}) {

  const columns = [
    { field: 'partner_name', headerName: 'From', flex: 1.5, headerClassName: 'super-app-theme--header-new' },
    { field: 'partner_name_to', headerName: 'To', flex: 1.5, headerClassName: 'super-app-theme--header-new' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header-new' },
    { field: 'item_name', headerName: 'Item', flex: 2, headerClassName: 'super-app-theme--header-new' },
    {
      field: 'invoice_qty',
      headerName: 'Qty',
      flex: 1,
      type: 'number',
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => params.value ? new Intl.NumberFormat('en-US').format(params.value) : new Intl.NumberFormat('en-US').format(params.row.order_qty)
    },
    { field: 'item_unit_of_measure', headerName: 'UoM', flex: 1, headerClassName: 'super-app-theme--header-new' },
    {
      field: 'item_price', headerName: 'Unit Price', flex: 1, headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
    },
    { field: 'lot_number', headerName: 'Lot Number', flex: 1, headerClassName: 'super-app-theme--header-new' },
    { field: 'expiration_date_raw', headerName: 'Exp. Date', flex: 1, headerClassName: 'super-app-theme--header-new' },
    {
      field: 'order_date', headerName: 'Order Date', flex: 1, headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return isNaN(date.getTime()) ? '' : date.toISOString().slice(0, 10);
      }
    },
  ];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      bgcolor: 'white',
      border: '1px solid #ccc',
      borderRadius: '15px',
      height: '83vh',
      maxHeight: '83vh',
    }}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          density="compact"
          loading={loading}
          disableRowSelectionOnClick
        />
      </Box>

    </Box>
  );
}
